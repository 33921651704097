<template>
    <v-dialog v-model="showMfaCodeInput" width="400" persistent>
        <v-card>
            <v-card-title>
                <v-spacer/><v-btn icon id="cancel-verification-button"><v-icon>cancel</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="pb-3">
                <p class="font-md black--text text--darken-1">{{ $t('message.twoFactorEmailEnabled') }}</p>
                <v-text-field
                        :placeholder="$t('message.enterAuthCode')"
                        class="my-2"
                        autocomplete="password"
                        dense
                        hide-details="auto"
                        id="verification-code-input"
                        solo
                        outlined
                        @keydown.enter.prevent="submit"
                />
                <p class="font-md black--text text--darken-1">{{ $t('message.twoFactorCodeExpiry') }}</p>
            </v-card-text>
            <v-card-actions>
                <div class="pb-3 d-flex flex-column align-center" style="width: 100%">
                    <v-btn class="info" id="continue-verification-button" width="100%">{{ $t('message.continue') }}</v-btn>
                    <v-btn :disabled="!enableResendCode" outlined class="mt-3" id="resend-verification-button"  width="100%">{{ enableResendCode ? $t('message.resendCode') : $t('message.resendCodeIn') + ' ' + resendCountdownTimerDisplay + ' ' + $t('message.seconds') + '.' }}</v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "VerificationCodeNew",
    data(){
        return {
            countdownStart: 175000,
            countdownTimer: 0,
            dialog: true,
        }
    },
    computed: {
        ...mapGetters([
            'showMfaCodeInput',
            'enableResendCode',
            'startVerificationCodeTimer'
        ]),
        resendCountdownTimerDisplay() {
            return Math.floor(this.countdownTimer / 1000)
        }
    },
    methods: {
        cancelVerification() {
            this.$emit('verification-cancelled')
            this.$store.commit('hideMfaCodeInputField')
        },
        resendTimer() {
            this.countdownTimer = this.countdownStart
            this.intervalId = setInterval(()=>{
                if(this.countdownTimer == 1000){
                    clearInterval(this.intervalId)
                    this.$store.commit('enableResendCodeLink')
                    if(this.startVerificationCodeTimer) this.$store.commit('resetTimer')
                } else {
                    this.countdownTimer -= 1000
                }
            }, 1000);
        },
        submit() {
            const verificationButton = document.getElementById('continue-verification-button');
            verificationButton.click()
        }
    },
    watch: {
        showMfaCodeInput(val) {
            if(val){
                this.resendTimer()
            }
        },
        startVerificationCodeTimer(val) {
            if(val){
                this.resendTimer()
            }
        }
    }
}
</script>

<style>
div.v-dialog.v-dialog--active {
    min-width: 400px !important;
    max-width: 500px !important;
}
</style>